import { AppBar, Toolbar, Typography, IconButton, Avatar } from '@material-ui/core'

import BooleanFormControl from '../BooleanFormControl'
import UserIconDisplay from './UserIconDisplay'
import { getAppStyles } from '../App/AppStyles'

const PageHeader = ({ darkMode, setDarkMode, userInfo }) => {
  const classes = getAppStyles()
  return (
    <AppBar position='relative'>
      <Toolbar>
        <Typography component='h1' variant='h6' color='inherit' noWrap className={classes.title}>
          <Avatar className={classes.defLogixLogo} src='/def-logix-d-larger_centered.png' />
          Def-Logix Security Manager
        </Typography>
        <BooleanFormControl
          title='Dark Mode (Beta)'
          formValuesKey=''
          onChange={() => setDarkMode(!darkMode)}
          value={darkMode}
        />
        <IconButton color='inherit'>
          <UserIconDisplay userInfo={userInfo} />
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}

export default PageHeader
