import React from 'react'
import { Paper, Button, Typography, Container } from '@material-ui/core'
import Footer from '../Footer'

import { getAppStyles } from '../App/AppStyles'

const DownloadAccess = () => {
  const classes = getAppStyles()

  return (
    <>
      <Container maxWidth='xl' className={classes.container}>
        <Paper className={classes.paper}>
          <Typography align='left' variant='h5' className={classes.contentTitle}>
            Download Access
          </Typography>
          <Typography>
            <p style={{ marginTop: '3rem' }}>
              Download the latest version of the Security Manager by clicking the button below.  Click the link
              and the software will automatically start downloading to your computer.
            </p>
            <p>
              Once the file has fully downloaded you can double click on that file in explorer to start the
              installation.  You will then need to insert a license key from your account to complete the installation.
            </p>
            <Button
              href='https://download.def-logix.com/analyzer/SecGapInstaller/SecurityManager-2202.msi'
              target="_blank"
              variant='contained'
              color='primary'
            >
              Download Security Mitigation Tool
            </Button>
          </Typography>
        </Paper>
      </Container>
      <Footer />
    </>
  )
}

export default DownloadAccess
