import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ExpandMore, ArrowDownward } from '@material-ui/icons'
import { Container, Accordion, AccordionSummary, AccordionDetails,
  Avatar, Button, Typography } from '@material-ui/core'
import Footer from '../Footer'
import { getLoginStyles } from '../Login/LoginStyles'
import fetchData from '../../utility/endpointFetch'

const NoMachineSetup = () => {
  const classes = getLoginStyles()
  const history = useHistory()
  const [firstLicenseKey, setFirstLicenseKey] = useState('')


  useEffect(() => {
    function getUserData () {
      var { promise, cancel } = fetchData('/users/user', 'Error retrieving user attached to account')
      ;(async () => {
        var response = await promise
        console.log("response in NoMachineSetup is: ")
        console.dir(response)
        if (response.any_machines) {
          //there are machines....no longer need to be here
          window.location.reload(false);
        } else {
          setFirstLicenseKey(response.first_license_key)
        }
      })()
    }
    getUserData()
    const interval = setInterval(() => getUserData(), 5000)
    return () => {
      clearInterval(interval);
    }
  }, [])

  return (
    <>
      <Container maxWidth='sm' className={classes.container}>
        <div className={classes.paper}>
          <Avatar className={classes.avatar} src='/def-logix-d-larger.png' />
          <Typography component='h1' variant='h5'>
            Install Mitigator To Your First Machine
          </Typography>
          <Typography align='left' variant='body1' className={classes.accordionSpacing} >
            You do not have your first machine connected to the Security Manager.  To continue to your account dashboard you will need
            to install the mitigation tool to a machine you wish to protect.  Choose the correct option below to complete that process.
          </Typography>
          <div className={classes.accordionSpacing}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="this-machine-content"
                id="this-machine-header"
              >
                <Typography variant='h6'>You ARE on the machine you wish to install the Security Mitigation Tool to? </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant='subtitle2'>
                  Great!  You only need to download the mitigation tool from the link below and install to the machine you are on.  Once installed this page will
                  refresh to your account dashboard and you will have full access to your account
                  <br />
                  <Button
                    variant='contained'
                    size='small'
                    color='primary'
                    startIcon={<ArrowDownward />}
                    href='https://download.def-logix.com//SecGapInstaller/latest/'
                    target="_blank"
                  >
                    Click to download Mitigation Tool
                  </Button>
                  <p>
                    Within the installation process of the installed tool you'll be asked for a license key.  Paste the following into that field to complete installation:
                  </p>
                  <p>License Key: {firstLicenseKey}</p>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="not-this-machine-content"
                id="not-this-machine-header"
              >
                <Typography variant='h6'>You are NOT on the machine you wish to install the Security Mitigation Tool to?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography  variant='subtitle2'>
                  Please go to the machine you wish to install the Security Mitigation Tool and login to this tool on that machine.  You can then complete installation to that machine from there.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  )
}

export default NoMachineSetup
